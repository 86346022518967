$ ->
  $.datetimepicker.setDateFormatter
    parseDate: (date, format) ->
      d = moment(date, format)
      d_alt = moment(date, 'DDMMYYHHmm')

      if d.isValid()
        d.toDate()
      else if d_alt.isValid()
        d_alt.toDate()
      else
        false
    formatDate: (date, format) ->
      moment(date).format format

  jQuery.datetimepicker.setLocale('de');
  window.initializeDateTimeSelectors = ->
    $('.timepicker').datetimepicker
      step: 15
      allowBlank: true
      dayOfWeekStart: 1
      defaultSelect: false
      scrollMonth: false
      scrollInput: false
      format:'YYYY-MM-DD HH:mm',
      formatTime:'HH:mm',
      formatDate:'YYYY-MM-DD'

    $('.date').datetimepicker
      timepicker: false
      allowBlank: false
      dayOfWeekStart: 1
      defaultSelect: false
      scrollMonth: false
      scrollInput: false
      format: "YYYY-MM-DD"
      formatTime:'HH:mm',
      formatDate:'YYYY-MM-DD'
      onSelectDate: (dp, $input) ->
        $input.closest('form').submit() if $input.data('submit-on-change')?

  initializeDateTimeSelectors()
