$ ->
  $(document).ajaxStart ->
    $('html:not(.no-loading-indicator)').addClass('loading-pre')

  $(document).ajaxComplete ->
    return if $.active > 1
    $('html:not(.no-loading-indicator)').removeClass('loading-pre')

  $(document).ajaxError (event, jqxhr, settings, thrownError) ->
    $('html:not(.no-loading-indicator)').removeClass('loading')
