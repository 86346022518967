$ ->
  sane_input_types = "input:not(.no-special-input-handling), select:not(.no-special-input-handling), button:not(.no-special-input-handling), textarea:not(.no-special-input-handling)"
  $("body").on "keydown", sane_input_types, (e) ->
    enter_key = 13
    tab_key = 9

    if e.keyCode in [tab_key, enter_key]
      self = $(this)
      form = self.parents('form:eq(0)')

      # some controls should just press enter when pressing enter
      if e.keyCode == enter_key
        if e.ctrlKey
          form.find('button:first').click().attr('disabled', true)
          return false

        switch self.prop('type')
          when 'submit'
            return true
          when 'textarea'
            value = $(this).val()
            return unless value.substr(value.lastIndexOf("\n") + 1) == ''

      # Sort by tab indexes if they exist
      tab_index = parseInt(self.attr('tabindex'))
      if tab_index
        input_array = form.find("[tabindex]").filter(':visible:enabled:not([readonly])').sort((a,b) ->
          parseInt($(a).attr('tabindex')) - parseInt($(b).attr('tabindex'))
        )
      else
        input_array = form.find(sane_input_types).filter(':visible:enabled:not([readonly])')

      # reverse the direction if using shift
      move_direction = if e.shiftKey then -1 else 1
      new_index = input_array.index(this) + move_direction

      # wrap around the controls
      if new_index == input_array.length
        new_index = 0
      else if new_index == -1
        new_index = input_array.length - 1

      if self.prop('type') == 'radio'
        new_index += move_direction while input_array.eq(new_index).prop('type') == 'radio'

      if e.keyCode == enter_key and
      new_index < input_array.length and
      input_array.eq(new_index).prop('type') == 'submit'
        return false if $('#form').attr('data-no-submit-on-return') == 'true' # hack for modal form with uld select in add_uld_modal partial
        form.find('button').not('[data-dismiss]').first().click()
        form.find('button:first:not(.no-disable)').attr('disabled', true)
        return false

      move_to = input_array.eq(new_index)
      move_to.focus().select()

      false
