import { Controller } from 'stimulus';

export default class ToggleFormInputController extends Controller {
  static targets = ['template', 'form', 'destroy', 'input', 'label'];

  addInput(event) {
    event.preventDefault();

    const content = this.templateTarget.content.cloneNode(true);

    const lastInput = [...this.inputTargets].pop();
    const lastId = lastInput ? lastInput.name.match(/\[(\d+)\]/)[1] : false;
    const newId = lastId ? parseInt(lastId, 10) + 1 : 0;

    content.querySelectorAll('input, label').forEach((element) => {
      if (element.hasAttribute('name')) {
        element.setAttribute(
          'name',
          element.getAttribute('name').replace(/\[\d+\]/, `[${newId}]`),
        );
      }
      if (element.hasAttribute('id')) {
        element.setAttribute(
          'id',
          element.getAttribute('id').replace(/_\d+_/g, `_${newId}_`),
        );
      }
      if (element.tagName === 'LABEL' && element.hasAttribute('for')) {
        element.setAttribute(
          'for',
          element.getAttribute('for').replace(/_\d+_/g, `_${newId}_`),
        );
      }
    });

    this.formTarget.appendChild(document.importNode(content, true));
  }

  removeInput(event) {
    event.preventDefault();
    const item = event.target.closest('.field');
    const destroyInput = this.destroyTargets.find((target) =>
      item.contains(target),
    );
    if (destroyInput) {
      item.remove();
    }
  }
}
