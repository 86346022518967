window.UldHelper = markDuplicates: ->

  findSurroundingDiv = (textarea) ->
    inputFieldId = $(textarea).attr('id') + '-selectized'
    inputField = $(document.getElementById(inputFieldId))
    surroundingDiv = inputField.parent()
    surroundingDiv

  textareas = $('textarea[id^=outbound_uld_build_uld_numbers]')
  idValueMap = {}
  textareas.each (index, textarea) ->
    textareaVal = $(textarea).val()
    if textareaVal
      if idValueMap[textareaVal]
        idValueMap[textareaVal].push textarea
      else
        idValueMap[textareaVal] = [ textarea ]
    return
  form = $('form[id^=edit_outbound_uld_build]')
  button = form.find(':submit')
  button.removeAttr 'disabled'
  textareas.each (_index, textarea) ->
    findSurroundingDiv(textarea).removeClass 'required-selectized'
    return
  $.each idValueMap, (textareaVal, textareas) ->
    if textareas.length >= 2
      $.each textareas, (_index, textarea) ->
        findSurroundingDiv(textarea).addClass 'required-selectized'
        button.attr 'disabled', true
        return
    return
  return
