const Uppy = require('@uppy/core')
const Dashboard = require('@uppy/dashboard')
const AwsS3 = require('@uppy/aws-s3')
const DragDrop = require('@uppy/drag-drop')

$(function() {
  const uppy = Uppy({autoProceed: true});

  uppy.use(AwsS3, { getUploadParameters(file) {
    return fetch(`/uploads/presign?filename=${file.name}`).then(response => response.json());
  }
}
  );

  if ($('.dropzone').length > 0) {
    uppy.use(Dashboard, {
      target: '.dropzone',
      inline: true,
      showProgressDetails: true,
      hideUploadButton: true,
      hideProgressAfterFinish: false,
      proudlyDisplayPoweredByUppy: false,
      locale: {
        strings: {
          dropPaste: 'Datei(en) hineinziehen oder %{browse}',
          browse: 'hier klicken'
        }
      }
    });
  }

  if ($('.dropzone-overlay').length > 0) {
    uppy.use(DragDrop, {
      target: '.dropzone-overlay',
      locale: {
        strings: {
          dropHereOr: 'Datei(en) hineinziehen',
          browse: ''
        }
      }
    });
  }

  uppy.on('upload-success', function(file, data) {
    const $form = $('form.new_attachment');

    const $fileInput = $('<input>').attr({
      type: 'hidden',
      name: 'attachment[files][]'
    });

    const uploadedFileData = JSON.stringify({
      id: file.meta['key'].match(/^cache\/(.+)/)[1],
      storage: 'cache',
      metadata: {
        size: file.size,
        filename: file.name,
        mime_type: file.type
      }});

    $fileInput.val(uploadedFileData);
    return $fileInput.appendTo($form);
  });

  return uppy.on('complete', function(result) {
    const $form = $('form.new_attachment');
    return $form.submit();
  });
});
