$ ->
  $("#filter-depot").change ->
    this[0].form.submit()

  $("#filter-type").change ->
    $("[id^=filter-value-for]").attr("disabled", true).val("").hide() # disable previous value field
    value_field = $("#filter-value-for-" + $(this).val()) # find current value field, if any
    if value_field[0] && !$(value_field[0]).data('submit-blank')
      value_field.attr("disabled", false).show().val("").focus()
    else # if there is none, just submit
      this.form.submit()

  $("[id^=filter-value-for]").change ->
    $this = $(this)
    # do not submit blank text / date fields (datepicker will make it lose focus)
    if ($this.is('input:text') && $this.val() is '') || $this.hasClass('date')
      return true
    else
      this.form.submit()
  .keypress (e) -> # this is only needed for ie8
    this.form.submit() if e.which == 13 && $(this).val() != ""

  # Initialize and show current filter value field
  $("#filter-value-for-" + $("#filter-type").val()).show().attr("disabled", false).focus().select()

  # Automatically switch to number filter on number key press (if a number filter is available)
  $(document).on 'keypress', (e) ->
    # Convenience
    $number_filter = $('#filter-type').find('[value=number]')

    # Don't do anything if...
    return true if(
      $number_filter.length == 0 ||                          # ...there is no number filter
      $number_filter.prop('selected') == true ||             # ...the number filter is already selected
      $(e.target).prop("tagName") == 'INPUT' ||              # ...we are currently within an input field
      e.which == 8 || isNaN(String.fromCharCode(e.which))    # ...we don't have a number
    )

    # Otherwise, switch to the number input
    $number_filter.prop 'selected', true
    $('#filter-type').trigger 'change'

  $(document).on 'click', '.filter-reveal', ->
    $(this).closest('.filter').removeClass('collapsed')
