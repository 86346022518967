import { Notifier } from '@airbrake/browser';

document.addEventListener('DOMContentLoaded', () => {
  if ((process.env.RAILS_ENV !== 'development') && (process.env.RAILS_ENV !== 'test')) {
    window.airbrake = new Notifier({
      projectId: process.env.AIRBRAKE_PROJECT_ID,
      projectKey: process.env.AIRBRAKE_PROJECT_KEY,
    });

    window.airbrake.addFilter((notice) => {
      const filter = notice;
      filter.context.environment = process.env.RAILS_ENV;
      filter.context.version = process.env.APP_VERSION;
      return filter;
    });
  }
});
