document.addEventListener('DOMContentLoaded', () => {
  let table = $('#stocktakingShipmentFindings-table')
  let tableClasses = [];
  if (table[0]) {
    tableClasses = $(table)[0].className.split(/\s+/);
  }
  let isInbound = tableClasses.includes('inbound');
  let columns = [
    { data: 'shipment_type', orderable: false },
    { data: 'shipment_client_name' },
    { data: 'shipment_number', className: 'mono' },
    { data: 'shipment_hawb_number', className: 'mono' },
    { data: 'shipment_destination', className: 'mono' },
    { data: 'shipment_first_checkedin_at' },
    { data: 'shipment_pieces', className: 'mono' },
    { data: 'pieces', className: 'mono' },
  ];
  if (isInbound) {
    columns.push({ data: 'customs_status' });
  }
  window.ajaxDataTableWithFilter({
    $element: $('#stocktakingShipmentFindings-table'),
    columns: columns,
    createdRow: function( row, data, dataIndex, cells ) {
      const lockedRegex = /.*Gesperrt.*/;
      const releasedRegex = /.*Freigegeben.*/;
      if (data.customs_status.match(lockedRegex)) {
        $(cells[8]).addClass("customs-locked");
      }
      if (data.customs_status.match(releasedRegex)) {
        $(cells[8]).addClass("customs-released");
      }
    },
    order: [[3, 'asc']],
  });
});
