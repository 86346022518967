$ ->
  # Fix form-referencing (outside) submit button in IE
  # FIXME: test me!
  $('button[form]').click ->
    form_id = $(this).attr('form')
    $('#' + form_id).submit()

  # Submit only once
  $('form').submit (e) ->
    if $(this).hasClass('submitted')
      e.preventDefault()
      return
    else
      $(this).addClass('submitted')

  # Auto-select first input in full forms
  $('form.full input[type=text], form.full select').first().focus().select()

  # Float
  $(document).on 'input change', '.float', (e) ->
    this.value = this.value.replace(/,/, '.').replace(/[^0-9\.]/gi, '')
  .focus ->
    $(this).select()

  # Currency
  $(document).on 'input change', '.currency', (e) ->
    this.value = this.value.replace(/,/, '.').replace(/[^0-9\.\-]/gi, '')
  .focus ->
    $(this).select()

  # Integer
  $(document).on 'input change', '.integer', ->
    this.value = this.value.replace(/[^0-9]/gi, '')
  .focus ->
    $(this).select()

  # Integer-Range
  $(document).on 'input change', '.integer-range', ->
    this.value = this.value.replace(/[^0-9\-]/gi, '')
  .focus ->
    $(this).select()

  # Float-Range
  $(document).on 'input change', '.float-range', ->
    this.value = this.value.replace(/[\,]/gi, '.')
    this.value = this.value.replace(/[^0-9\-\.]/gi, '')
  .focus ->
    $(this).select()

  # Alpha integer
  $(document).on 'input change', '.alpha-integer', ->
    this.value = this.value.replace(/[^0-9a-zäöüÄÖÜß\n]/gi, '') # FIXME backspace is broken

  # License Plate
  $(document).on 'input change', '.license-plate', ->
    this.value = this.value.replace(/[^0-9a-zäöüÄÖÜß\ \-\n]/gi, '')
  $(document).on 'input change', '.license-plate-input .selectize-input input', ->
    this.value = this.value.replace(/[^0-9a-zäöüÄÖÜß\ \-\n]/gi, '')

  $(document).on 'click', 'input.select-all', ->
    this.select()

  # FIXME what's this doing again?
#  $(document).bind "keydown keypress", (e) ->
#    e.preventDefault() if(e.which == 8 && !$(e.target).is("input[type=text]:not([readonly]), input[type=password]:not([readonly]), textarea"))
