document.addEventListener('DOMContentLoaded', () => {
  window.ajaxDataTableWithFilter({
    $element: $('#inboundMasterShipments-table'),
    columns: [
      { data: 'hazard_class' },
      { data: 'number', className: 'mono' },
      { data: 'hawb_number', className: 'mono' },
      { data: 'origin' },
      { data: 'destination' },
      { data: 'client_name' },
      { data: 'pieces', className: 'text-right mono' },
      { data: 'weight', className: 'text-right mono' },
      { data: 'depot_name' },
      { data: 'pieces_checkedin', className: 'text-center mono' },
      { data: 'pieces_checkedout', className: 'text-center mono' },
      { data: 'id', orderable: false },
    ],
    order: [[2, 'asc']],
    paging: false,
  });

  window.ajaxDataTableWithFilter({
    $element: $('#chooseInboundMasterShipments-table'),
    columns: [
      { data: 'id', orderable: false },
      { data: 'hazard_class' },
      { data: 'number', className: 'mono' },
      { data: 'hawb_number', className: 'mono' },
      { data: 'origin' },
      { data: 'destination' },
      { data: 'client_name' },
      { data: 'pieces', className: 'mono' },
      { data: 'weight', className: 'mono' },
      { data: 'pieces_checkedin', className: 'text-center mono' },
      { data: 'pieces_checkedout', className: 'text-center mono' },
    ],
    order: [[0, 'desc']],
  });

  window.markAndUnmarkRowItems('chooseInboundMasterShipments');
});
